import React from 'react';

import {Link} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';


import './header.styles.css'

const Header = () => {




    return(
        <div className='header'>
            <Navbar fixed="top" className='navi' collapseOnSelect expand="lg"  >
                <Container>
                    <Navbar.Brand >
		    <Link to='/'>
                    <img
                        
                        src={window.location.origin + '/images/mce-svg-2.svg'}
                        className="d-inline-block align-top image-brand"
                        alt="React Bootstrap logo"
                    />
                    </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle  aria-controls="responsive-navbar-nav"  className="custom-toggler navbar-toggler ml-auto"  />
                        <Navbar.Collapse id="responsive-navbar-nav ">
                            <Nav className="test text-center">
                            <NavLink className='link' ><Link  onClick={() => window.scrollTo(0, 0)} className='link'to='/'>HOME</Link></NavLink>
                            <NavLink className='link' href='/#about-us'>ABOUT</NavLink>
                           
                            <NavLink className='link' href='/#services' >SERVICES</NavLink>
                            <NavLink className='link' ><Link  onClick={() => window.scrollTo(0, 0)} className='link' to='/projects'>PROJECTS</Link></NavLink>
                            <NavLink className='link' ><Link  onClick={() => window.scrollTo(0, 0)} className='link'to='/enviroment'>ENVIRONMENT</Link></NavLink>
                            <NavLink className='link' ><Link  onClick={() => window.scrollTo(0, 0)} className='link' to='/get-in-touch'>GET IN TOUCH</Link></NavLink>

                            </Nav>

                            <div className='text-center'>
                            <a href="images/MCE-COMPANY-PROFILE.pdf" download>
                                <button className='button2'>Download our profile</button>
                             </a>
                            </div>

                        </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )

}


export default Header;
