import React from 'react';
import  Container  from 'react-bootstrap/Container';



import './landing-page.styles.css';

const LandingPage = () => {
    return (
        <div className ='landing-page ' style={{ backgroundImage: `url('/images/home2.jpg')` }}>
            <Container>
            <div className="landingmain mt-5 ">
                <img className='image-land' src={window.location.origin + '/images/mce-svg-2.svg'} alt="contact" />
               
            </div>
            </Container>
        </div>
    );
}

export default LandingPage;