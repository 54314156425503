import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './heading.styles.css';

const Heading = ({children}) => {
    return (
        <div className='heading'>
            <Row>
                <Col><h1 className ='heading-header'>{children}</h1><hr className='underline'/></Col>
            </Row>
        </div>
    )
}

export default Heading;