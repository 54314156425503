import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';

import Button from '../../components/button/button.component';

import './page-break.styles.css';

const PageBreak = () => {

    return (
        <div className='page-break main-sec'>
            <Container  fluid={true}>
                <Row className='g-0'>
                    <Col sm={12} md={12} lg={6} xl={6}><img className='page-break-image' src={window.location.origin + '/images/homepage1.jpg'} alt="contact" /></Col>

                    <Col  className='col-back'>
                        <div className='content'>
                            <h3 className='heading'>Health and safety plan</h3>
                            <h6 className='sub-head mt-2'>
                                To ensure that the Health and Safety of all managers, personnel and associated parties is not compromised, our firm has adoped certain procedures.
                            </h6>
                            <Link  onClick={() => window.scrollTo(0, 0)} to='health-and-safety'><Button>View procedures</Button></Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PageBreak;
