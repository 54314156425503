import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';

import Heading from '../heading/heading.component';

import './about.styles.css';

const About = () => {
    return (
        <div className='about main-sec'>
            <Container>
                <Heading>About us</Heading>
                <Row>
                    <Col sm={12} md={12} lg={12} xl={12}><h4 className='heading-not-main'>Who we are</h4></Col>
                </Row>

                <Row className='mt-4 mb-5'>
                    <Col sm={12} md={12} lg={12} xl={12}>
                  <p>      Maragela consulting engineers was formed with a goal of providing an engineering and project management service with high standards of delivery and quality.
                        Mr Motshedi Nathane is the chief executive officer of the company and he holds a B. Tech in civil engineering. He has also graduated with a master in business
                        administration from Monash University. He is also a registered professional with the engineering council pf South Africa. He ahs more than 20 years experience
                        in the consulting envioroment with essential skills in project management, engineering design and project implementation</p>

                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <img className='about-image' src={window.location.origin + '/images/team.jpg'} alt="contact" />
                    </Col>

                    <Col sm={12} md={6} lg={6} xl={6}>
                        <Accordion className='accord1' defaultActiveKey="0">
                            <Card className='accord'>
                                <Card.Header className='accord'>
                                <Accordion.Toggle className='accord' as={Card.Header} variant="link" eventKey="0">
                                    <h4 className='heading-not-main'>Vision  &#8594;</h4>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body className='mar'>
                                  <p>  The vision of Maragela Consulting Engineers is to create sustainable jobs to a considerable number of previously disadvantaged individuals in both rural and urban
                                    communities. The company also thrives to be an effective force of commercially sustainable local communities through the provision of the necessary training and development
                                    programmes and to nourish and harness the skills and training within local communities to off-set the present high level of unemployment in our country.</p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card className='accord'>
                                <Card.Header className='accord'>
                                <Accordion.Toggle className='accord' as={Card.Header} variant="link" eventKey="1">
                                    <h4 className='heading-not-main'>Mission   &#8594;</h4>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body className='mar'>
                                  <p>                                    The mission statement of Maragela Consulting Engineers is dedicated to broad based value added empowerment, with the dawn of the African Renaissance we are ready to widen our horizon
                                                                      and embrace the future of civils and construction with more vigour and optimism. Our primary objectives are to contribute to the generation of balanced, sustainable and economic
                                                                      empowerment of the South African population, thereby promoting the economic prosperity for all citizens. We have achieved these objectives by building a competitive company based
                                                                      on sound business principles and good corporate governance</p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col sm={12} md={4} lg={4} xl={4}>
                        <img className='about-image' src={window.location.origin + '/images/about1.jpg'} alt="contact" />
                    </Col>
                    <Col sm={12} md={4} lg={4} xl={4}>
                        <img className='about-image' src={window.location.origin + '/images/about2.jpg'} alt="contact" />
                    </Col>
                    <Col sm={12} md={4} lg={4} xl={4}>
                        <img className='about-image' src={window.location.origin + '/images/about3.jpg'} alt="contact" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}


export default About;
