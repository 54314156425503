import React from 'react';

import './google-maps.styles.css';

const GoogleMaps = () => {
    return (
        <div className ='google-maps'>
            <iframe title='google-maps'className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.301251686405!2d27.868509815185483!3d-26.089083115639273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e959ef166c3b533%3A0x5f074999fe8e299e!2sRuimsig%20Office%20Estate!5e0!3m2!1sen!2sza!4v1623740520310!5m2!1sen!2sza"  ></iframe>
        </div>
    )
}

export default GoogleMaps;
