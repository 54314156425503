import React from 'react';

import './button.styles.css'

const Button = ({children}) => (
    <div className='button'>
        <button className='button1'> {children}</button>
    </div>
);

export default Button;