import React from 'react';

import GoolgeMaps from '../../components/google-maps/google-maps.component';
import Heading from '../../components/heading/heading.component';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './get-in-touch-page.styles.css';

const GetInTouchPage = () => {
    return (
        <div className ='get-in-touch-page main-sec'>
            <Container>
                <Heading>Get in touch</Heading>

                <Row>
                    <Col>
                    <h2 className='branches heading-not-main'>Head office</h2>
                        <GoolgeMaps/>
                    </Col>
                </Row>

                <Row>
                    <Col><h2 className='branches mb-5 heading-not-main'>Branches</h2></Col>
                </Row>

                <Row className='text-center'>
                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Row><Col><b className='heading-not-main'>MPUMALANGA</b></Col></Row>
                        <Row><Col>Suite 11, Nedbank Centre</Col></Row>
                        <Row><Col>6A OR Tambo Street</Col></Row>
                        <Row><Col>Midderlburg</Col></Row>
                        <Row><Col>Not Certified</Col></Row>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Row><Col><b className='heading-not-main'>NORTH-WEST</b></Col></Row>
                        <Row><Col>214 Beyers Naude Drive</Col></Row>
                        <Row><Col>Ground Floor, Regus Building</Col></Row>
                        <Row><Col>Rustenburg</Col></Row>
                        <Row><Col>Not Certified</Col></Row>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Row><Col><b className='heading-not-main'>FREE STATE</b></Col></Row>
                        <Row><Col>94 Kellner Street</Col></Row>
                        <Row><Col>Westdene</Col></Row>
                        <Row><Col>Bloemfontein</Col></Row>
                        <Row><Col>ISO 9001:2015 Certified</Col></Row>
                    </Col>
                </Row>
            </Container>
            
        </div>
    )
}

export default GetInTouchPage;
