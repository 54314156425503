import React from 'react';

import Heading from '../heading/heading.component';
import PageBreak from '../../components/page-break/page-break.component';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import About from '../about/about.component';

import Jumbo from '../jumbo/jumbo.compoent';

import './home-page-content.styles.css';


const HomePageContent = () => {
    return (
        <div className='home-page-content '>
            <Container id='about-us'>
                <About/>
            </Container>
            <Jumbo/>


            <Container id='services'>
                <Heading>Our services</Heading>

                <Row >
                    <Col sm={12} md={3} lg={3} xl={3} className='text-center'>
                        <img className='image-serv' src={window.location.origin + '/images/projects.png'} alt="contact" />
                        <h3 className='heading-serv'>Project management</h3>
                    </Col>
                    <Col sm={12} md={3} lg={3} xl={3} className='text-center'>
                        <img className='image-serv' src={window.location.origin + '/images/civil.png'} alt="contact" />
                        <h3 className='heading-serv'>Civil engineering</h3>
                    </Col>
                    <Col sm={12} md={3} lg={3} xl={3} className='text-center'>
                        <img className='image-serv' src={window.location.origin + '/images/structural.png'} alt="contact" />
                        <h3 className='heading-serv'>Structural engineering</h3>
                    </Col>
                    <Col sm={12} md={3} lg={3} xl={3} className='text-center'>
                        <img className='image-serv' src={window.location.origin + '/images/rain.png'} alt="contact" />
                        <h3 className='heading-serv'>Roads & stormwater design</h3>
                    </Col>
                </Row>

                <Row >
                    <Col sm={12} md={3} lg={3} xl={3} className='text-center'>
                        <img className='image-serv1' src={window.location.origin + '/images/rural.png'} alt="contact" />
                        <h3 className='heading-serv'>Rural infrastructure design</h3>
                    </Col>
                    <Col sm={12} md={3} lg={3} xl={3} className='text-center'>
                        <img className='image-serv1' src={window.location.origin + '/images/road.png'} alt="contact" />
                        <h3 className='heading-serv'>Transportation planning &  technology</h3>
                    </Col>
                    <Col sm={12} md={3} lg={3} xl={3} className='text-center'>
                        <img className='image-serv1' src={window.location.origin + '/images/sanitation.png'} alt="contact" />
                        <h3 className='heading-serv'>Water & sanitation networks</h3>
                    </Col>
                    <Col sm={12} md={3} lg={3} xl={3} className='text-center'>
                        <img className='image-serv' src={window.location.origin + '/images/urban.png'} alt="contact" />
                        <h3 className='heading-serv'>Urban infrastructure & township service design</h3>
                    </Col>
                </Row>
            </Container>

            <PageBreak/>
        </div>
    )
}

export default HomePageContent;
