import React, {useState} from 'react';

import './projects-page.styles.css';

import {data} from './data';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import Card from   'react-bootstrap/Card';

import Heading from '../../components/heading/heading.component';

const ProjectPage = () => {
    const [projects] = useState(data);

    return(
        <div  className='projects-page main-sec'>
            <Container>
                <Row>
                    <Heading>Projects</Heading>
                </Row>

                <Row>
                    {
                        projects.map((project) => {
                            const {id, desc, total, client, contact, contactNum} = project;

                            return(
                                <Col sm={12} md={4} lg={3} xl={3}>
                                    <Card className='def' key={id}>
                                        <Card.Body>
                                            <Card.Title className='title1111'>{client}</Card.Title>
                                            <Card.Subtitle className="mb-1 text-muted">{total}</Card.Subtitle>
                                            <Card.Text className='text-muted cccc'>
                                                {desc}
                                            </Card.Text>
                                          
                                                <Row><Col><p  className='ccccc'>{contact}</p></Col></Row>
                                                <Row><Col><p  className='ccccc'>{contactNum}</p></Col></Row>
                                            
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default ProjectPage;