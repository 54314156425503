import React from 'react';

import Landing from '../../components/landing-page/landing-page.component';
import HomePageContent from '../../components/home-page-content/home-page-content.component';

import  './home-page.styles.css';

const  HomePage = () => {
    
    return(
        <div  className='home-page'>
            <Landing/>
            <HomePageContent/>
        </div>
    )
}

export default HomePage;