import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Heading from '../../components/heading/heading.component';

import './health-safety-page.styles.css';

const HealthSafetyPage = () => {
    return (
        <div className='health-safety-page main-sec'>
            <Container>
                <Heading>Health and safety plan</Heading>

                <Row>
                    <Col> <p className='text'>&#8226; To complete an in-house risk assessment at the initiate of the preliminary design stage pf each project.</p></Col>
                </Row>

                <Row>
                    <Col> <p className='text'>&#8226; To keep a copy of the Occupational Health and Safety Act, 1998 as well as the Occupational Health and Safety Construction Regulations 2003, in our offices at all times for reference purposes. </p></Col>
                </Row>

                <Row>
                    <Col> <p className='text'>&#8226; To keep and actively update a Health and Safety file in the office. </p></Col>
                </Row>

                <Row>
                    <Col> <p className='text'>&#8226; To determine the relevant legal regulations and provisions from the Occupational Health and Safety act 1998 and the OHS Construction regulations
                    , as well as other code of practice documentation and compile programmers and processes to ensure conformance. </p></Col>
                </Row>

                <Row>
                    <Col> <p className='text'>&#8226; To 'think out of the box' and produce further applicable and necessary health and safety standards on specific projects where there is a lack of applicable  legal literature.
                     </p></Col>
                </Row>

                <Row>
                    <Col> <p className='text'>&#8226; To preform induction sessions, weekly safety meetings, in service training, education, supervision and safety cross-checks
                    with all involved staff, managers and directors.
                     </p></Col>
                </Row>

                <Row>
                    <Col> <p className='text'>&#8226; To ensure the correct and compulsory use of protective clothing such as toe-protective work boots, hard hats, day glow vests, full body harnesses and fall arrestors, etc. </p></Col>
                </Row>

                <Row>
                    <Col> <p className='text'>&#8226; To arrange feed-back sessions at the completion of projects to discuss and report on health and safety issues and contribute
                    recommendations for our firms future reference purpose. </p></Col>
                </Row>

                <Row>
                    <Col> <p className='text'>&#8226; To provide professional Occupational Health and Safety services for clients in the form of OHS specification compilation for projects. </p></Col>
                </Row>

                <Row>
                    <Col> <p className='text'>&#8226; Alternatively, providing professional OHS risk assessment and planning services for contractors and assisting with OHS inspections
                    and the OHS file keeping, where the OHS specification already forms part of the contract documentation. </p></Col>
                </Row>
            </Container>
        </div>
    )
}

export default HealthSafetyPage;