import  React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Link} from 'react-router-dom'

import {AiFillFacebook} from 'react-icons/ai'

import './footer.styles.css';

const  Footer = () => {
    var d = new Date();


    return (
        <div className='footer text-center'>
            <Container>
                <Row>
                    <Col sm={12} md={4} lg={4} xl={4}>
                        <h2 className='head'>Head offices</h2>
                        <Row><Col><b>GAUTENG</b></Col></Row>
                        <Row><Col>Ruimsig Office Estate</Col></Row>
                        <Row><Col>Cnr hole in one and peter road</Col></Row>
                        <Row><Col>Ruimsig</Col></Row>
                        <Row><Col>ISO 9001:2015 certified</Col></Row>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                        <h2 className='head'>Contact details</h2>
                        <Row><Col><b>Mr. Motshedi Nathane</b></Col></Row>
                        <Row><Col>Tel:(011) 958-2403</Col></Row>
                        <Row><Col>Fax:(011) 958-1318</Col></Row>
                        <Row><Col>Email: Admin@maragelaconsulting.co.za</Col></Row>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                    <h2 className='head'>Links</h2>
                        <Row><Col><Link onClick={() => window.scrollTo(0, 0)} className='link-footer'to='/' >Home</Link></Col></Row>
                        <Row><Col><Link  className='link-footer'to='/enviroment' onClick={() => window.scrollTo(0, 0)}>Environment</Link></Col></Row>
                        <Row><Col><Link  className='link-footer'to='/projects' onClick={() => window.scrollTo(0, 0)}>Projects</Link></Col></Row>
                        <Row><Col><Link  className='link-footer'to='/health-and-safety' onClick={() => window.scrollTo(0, 0)}>Health and safety</Link></Col></Row>
                        <Row><Col><Link  className='link-footer'to='/get-in-touch' onClick={() => window.scrollTo(0, 0)}>Get in touch</Link></Col></Row>

                    </Col>
                </Row>

                <Row>
                        <Col>
                        <a href='https://www.facebook.com/Maragela-Consulting-Engineer-Pty-Ltd-2397943653770035/'><AiFillFacebook className='facebook'/></a>
                        </Col>

                </Row>

                

                <Row>
                        <Col>
                        <h6 className='footing mt-3'> ALL RIGHTS RESERVED &copy; {d.getFullYear()} Maragela Consulting Engineers (PTY) LTD</h6>
                        </Col>

                </Row>
                <Row>
                <h6 className='footing'>Website Designed by Softlife IT Solutions.</h6>
                </Row>
            </Container>
        </div>
    )
}

export default Footer;
