import  HomePage from './pages/home-page/home-page.component';
import Header from './components/header/header.component';
import AboutUsPage from './pages/about-us-page/about-us-page.component';
import HealthSafetyPage from './pages/health-safety-page/health-safety-page.component';
import GetInTouchPage from './pages/get-in-touch-page/get-in-touch-page.component';
import ProjectsPage from './pages/projects-page/projects-page.component';
import Footer from './components/footer/footer.component';

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

function App() {
  return (
    <Router>
        <div className="App">
        <Header/>
                <Switch>

                        <Route path="/projects">
                            <ProjectsPage/>
                        </Route>

                        <Route path="/get-in-touch">
                            <GetInTouchPage />
                        </Route>

                        <Route path="/health-and-safety">
                            <HealthSafetyPage />
                        </Route>

                        <Route path="/enviroment">
                            <AboutUsPage />
                        </Route>

                        <Route path="/">
                            <HomePage />
                        </Route>
                </Switch>
        <Footer/>
        </div>
    </Router>
  );
}

export default App;
