import React from 'react';

import About from '../../components/about/about.component';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Heading from '../../components/heading/heading.component';
import './about-us-page.styles.css';

const AboutUsPage = () => {
    return(
        <div className = 'about-us-page '>
         

            <Container>
                <Heading>Environmental management undertaking</Heading>

                <Row>
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <p>Our firm acknowledges the interrelation between social, economic and natrual environments and accepts that all development must occur in a responsible manner
                        to ensure the sustainability of these. To this end, our company endeavours to conform to SABS ISO 14001: 1996 (as revised): Environmental management systems Specification
                        with guidance for use and adopts the principles and goals stipulated in the United Nations Sustainable Development Document Agenda 21. </p>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Card className='issues'>
                            <Card.Body>
                                <Card.Title className='heading-not-main'>Involvement with environmental managment case 1</Card.Title>
                                <Card.Text className='tititit'>
                                    <p>As part of our project management services, our firm will arrange and supervise Environmental Impact Assessments where required
                                    due to socio-economic or natrual environmental implications.</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Card className='issues'>
                            <Card.Body>
                                <Card.Title className='heading-not-main'>Involvement with environmental managment case 2</Card.Title>
                                <Card.Text className='tititit'>
                                    <p>To actively seek engagement in eco-tourism type projects.</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col sm={12} md={4} lg={4} xl={4}>
                        <Card className='issues'>
                            <Card.Body>
                                <Card.Title className='heading-not-main'>Involvement with environmental managment case 3</Card.Title>
                                <Card.Text className='tititit'>
                                  <p>  As part of our skills development and transfer process, our staff and directorship are encouraged to attend courses,
                                    talks and seminars and to involve themselves in goverining bodies which are related to the industry.</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}


export default AboutUsPage;