export const data = [
    {
        id: 1,
        desc:'Design and Project Management for the new sewer reticulation  system',
        total: "ZAR  15.0 mil",
        client: "Potchefstroom Local Municipality",
        contact:'Mr. Chrito Stoltz',
        contactNum:'018 299 5436 /  083 447 3246'
    },
    {
        id: 2,
        desc:'Structural Design for the Toekomsrus Business hives.',
        total: "R 0.7 mil",
        client: "Randfontein Local Municipality",
        contact:'Mr. Tshidiso Moeketsi',
        contactNum:'(011)-411-0255'
    },
    {
        id:3,
        desc:'Foundation Design and Project Management for RDP houses.',
        total:'R 2.5 mil',
        client:'Barmose Construction GDP',
        contact:'Mr. Aaron Bolale',
        contactNum:'083-627-5995 / (015) 295-6965'

    },
    {
        id:4,
        desc:'Structural Steel design of the temporary market at Mabopane Station',
        total:'R 6.0 mil',
        client:'Prasa Cres',
        contact:'Mr. I. Musiyiwa',
        contactNum:'079-367-2176 / (012) 343-7203'

    },
    {
        id:5,
        desc:'Design and Project Management of Ablution Facilities',
        total:'R 0.5 mil',
        client:'Johannesburg Zoo',
        contact:'Mr. Reginald Mokalapa',
        contactNum:'078-578-3589 / (011) 999 - 6812'

    },
    {
        id:6,
        desc:'Design and Construction Monitoring for the construction of Farquharson road',
        total:'R 9.0 mil',
        client:'Ekurhuleni Metropolitan Municipality',
        contact:'Mr. Nape Seopela',
        contactNum:'083-499-3199 / (011) 999-6812'

    },
    {
        id:7,
        desc:'Design and Construction Monitoring for the construction of Pretoria Road',
        total:'R 68.0 mil',
        client:'Ekurhuleni Metropolitan Municipality',
        contact:'Mr. Nape Seopela',
        contactNum:'083-499-3199 / (011) 999-6812'

    },
    {
        id:8,
        desc:'Design and Construction Monitoring for the construction of Agricultural holding roads at Brentworth park',
        total:'R 37.0 mil',
        client:'Ekurhuleni Metropolitan Municipality',
        contact:'Mr. Nape Seopela',
        contactNum:'083-499-3199 / (011) 999-6812'

    },
    {
        id:9,
        desc:'Mentorship for EPWP learnership programme project',
        total:'R 5 mil',
        client:'Nkangala District Municipality',
        contact:'Mr.Makonde Nekhavhambe',
        contactNum:'(013)249-2145'

    },
    {
        id:10,
        desc:'Project Management for the Installation of railway turnouts',
        total:'R 98.0 mil',
        client:'Polyzomba/Spoornet',
        contact:'Mr. Sifiso Pretorius',
        contactNum:'083-687-6258 / (011-039-1148)'

    },
    {
        id:11,
        desc:'Upgrade of Simon Gondwe Sports Centre Pavilion',
        total:'R 1 mil',
        client:'Nkangala District Municipality',
        contact:'Ms. Sipokazi Wakeni',
        contactNum:'084-810-7215 / (013) 249 2235'

    },
    {
        id:12,
        desc:'Design and Project management of the New Ivory Park Swimming Pool',
        total:'R 30 mil',
        client:'City Of Johannesburg',
        contact:'Sello Mosala',
        contactNum:'083-369-3916 / (011) 375-7155'

    },
    {
        id:13,
        desc:'Refurbishment of he Kroomdrai Building',
        total:'R 1.0 mil',
        client:'West Rand District',
        contact:'Mr Danny Govender',
        contactNum:'(011) 411- 5057'

    },
    {
        id:14,
        desc:'Feasibility study for the Greater Tubatse Bulk water supply scheme',
        total:'R 9.0 mil',
        client:'National Treasury',
        contact:'Mr Jabu Shongwe',
        contactNum:'(013) 231-1000 / 082-904-9042'

    },
    {
        id:15,
        desc:'Design of the Elephant Enclosure',
        total:'R 1.5 mil',
        client:'Johannesburg Zoo',
        contact:'Mr Reginald Mokalapa',
        contactNum:'083-291-1320 / (011)646-2000'

    },
    {
        id:16,
        desc:'Flood analysis and Cicil designs at the SAPS National Intervention Centre',
        total:'R 15.0 mil',
        client:'Department of Public Works - Pretoria',
        contact:'Mr Isaack Moholoane',
        contactNum:'079-683-3650'

    },
    {
        id:17,
        desc:'Roads and Stormwater Master planning - Victor Khanye',
        total:'R 1.5 mil',
        client:'Nkangala District Municipality',
        contact:'Mr Lucky Msoki        ',
        contactNum:'(013)249-2145 / 078-728-3115'

    },
    {
        id:18,
        desc:'Roads and Stormwater Master planning - Thembisili Hani',
        total:'R 0.5 mil',
        client:'Nkangala District Municipality',
        contact:'Mr Lucky Msoki        ',
        contactNum:'(013)249-2145 / 078-728-3115'

    },
    {
        id:19,
        desc:'Design and Project Management of Paved road for Johannesburg         ',
        total:'R 1.0 mil        ',
        client:'Johannesburg Zoo',
        contact:'Mr Reginald Mokalapa',
        contactNum:'083-291-1320 / (011)646-2000'

    },
    {
        id:20,
        desc:'Structural Engineering Services for Construction of new Community Library at Mohlakeng Township         ',
        total:'R 5.0 mil        ',
        client:'Randfontein Local Municipality',
        contact:'Hosea Lidovho        ',
        contactNum:'083-538-3082 /(011) 411-0192        '

    },
    {
        id:21,
        desc:'Design and Project Management of 250 RDP houses at Hammanskraal Ext 1        ',
        total:'R 11.2 mil         ',
        client:'Gauteng Department of Housing         ',
        contact:'Mr Shendel Nkuna         ',
        contactNum:'(012) 303-3300        '

    },
    {
        id:22,
        desc:'Extension of the Pavilion Mall In Durban.        ',
        total:'R 40 mil        ',
        client:'WSP/Motseng Project Management         ',
        contact:'Mr Dewald Van Staden        ',
        contactNum:'(012) 348-1578/083-538-3082        '

    },
    {
        id:23,
        desc:'Lead Consultant for the detail Design of Intermodal Transport Facilities at Giyani        ',
        total:'R210.0 mil        ',
        client:'Prasa group - Intersite Investments         ',
        contact:'Mr. Patrick Gombert         ',
        contactNum:'079-889-0341 / (011) 502-8300        '

    },
    {
        id:24,
        desc:'Lead Consultant for the Concept Design if Intermodal Transport Facilities at Makhado.        ',
        total:'R 162.0 mil        ',
        client:'Prasa group - Intersite Investments ',
        contact:'Mr. Patrick Gombert',
        contactNum:'079-889-0341 / (011) 502-8300 '

    },
    {
        id:25,
        desc:'Design and Project Management of 260 RDP houses at Soshanguve SS section         ',
        total:'R 11.7 mil        ',
        client:'Gauteng Department of Housing         ',
        contact:'Mr. Funsilw Xhakaza        ',
        contactNum:'(012) 303 - 3300        '

    },
    {
        id:26,
        desc:'Civil and Project Management Services for the Bulk water and sewer reticulation services at Bohlokong         ',
        total:'R 5.0 mil        ',
        client:'Dihlabeng Local Municipality         ',
        contact:'Mr. Afrika Masuka         ',
        contactNum:'082-898-2895 /(058)408-1469        '

    },
    {
        id:27,
        desc:'Civil and Structural Services for the Construction of ARV clinics in various Distructs in the Free State         ',
        total:'R 46.0 mill        ',
        client:'Department of Health         ',
        contact:'Mr. Aaron Baartman         ',
        contactNum:'082-849-5423 / (051) 408-1469        '

    },
    {
        id:28,
        desc:'Design of Informal traders business hives         ',
        total:'R 5.0 mil        ',
        client:'Merafong Local Municipality         ',
        contact:'Mr. Daan Venter         ',
        contactNum:'078-451-3070/ (018) 788-9020        '

    },
    {
        id:29,
        desc:'Structural Engineering Services for Fochville Boarding School        ',
        total:'R 150.0 mil        ',
        client:'Gauteng Department of Infrastructure Development         ',
        contact:'Mr. Jefta Mavatha        ',
        contactNum:'(011) 429-3619/ 083-299-0187        '

    },
    {
        id:30,
        desc:'Civil and Structural Services for the Construction of a Boarding Facility at Machadadorp        ',
        total:'R 191.0 mil        ',
        client:'Independent Development Trust         ',
        contact:'Mr. Tebogo Seshibe         ',
        contactNum:'(013) 756-5500        '

    },
    {
        id:31,
        desc:'Design and Project of Manapo Nursing Collage         ',
        total:'R 11.0 mil        ',
        client:'Department of Health         ',
        contact:'Mr. Luvuyo Penze        ',
        contactNum:'078-926-3527/ (051) 408-1415        '

    },
    {
        id:32,
        desc:'Consulting Engineering Services for Zola Butt Hut, Emdeni Library, Elka Sports Ground        ',
        total:'R 20 mil        ',
        client:'City Of Joburg - Community Development         ',
        contact:'Mr.Sello Mosala         ',
        contactNum:'073-470-662/ (011)743-0185        '

    },
    {
        id:33,
        desc:'Design and Project Management of Sewer Infrastructure at various Station        ',
        total:'R 11.0 mil        ',
        client:'Prasa Cres         ',
        contact:'Mr. Muhali Mukwevho        ',
        contactNum:'079-493-8362/ (012) 743-0185        '

    },
    {
        id:34,
        desc:'Design and Project Management for the upgrading of 5 (five) Pelonomi Hospital Sections.        ',
        total:'R 120 mil        ',
        client:'Department of Health         ',
        contact:'Mr. Luvuyo Penze        ',
        contactNum:'078-926-3527/ (051) 408-1415        '

    },
    {
        id:35,
        desc:'Design and Project Management of Waterdown dam outlet pipes         ',
        total:'R 3.5 mil        ',
        client:'Department of Water Affairs         ',
        contact:'Mr. Walther Oosthuizen         ',
        contactNum:'082-807-3527/ (012) 336-6651        '

    },
    {
        id:36,
        desc:'Upgrading of Khutsong Proper Stadium         ',
        total:'R 6.0 mil        ',
        client:'Merafong Local Municipality         ',
        contact:'Mr. Daan Venter         ',
        contactNum:'078-451-3070/ (018) 788-9020        '

    },
    {
        id:37,
        desc:'Design and Project Management of Amelia Community Health Centre        ',
        total:'R98.0 mil        ',
        client:'Department of Health         ',
        contact:'Mr. Aaron Baartman         ',
        contactNum:'082-849-5423/ (051) 408-1415        '

    },
    {
        id:38,
        desc:'Civil and Structure Services for Refurbishments at Chris Hani Baragwanath Hospital         ',
        total:'R 5.5 mil        ',
        client:'Gauteng Department of Infrastructure Development         ',
        contact:'Mr. Thami Mashinini        ',
        contactNum:'(011) 429-4055/ 079-214-4055        '

    },
    {
        id:39,
        desc:'Upgrading of Eco Heritage Centre and Chalets         ',
        total:'R 4.0 mil        ',
        client:'Department of Rutal Development and Land Reform         ',
        contact:'Ms.  Cebisa Goboza         ',
        contactNum:'073-274-5043/ (043)700-7000        '

    },
    {
        id:40,
        desc:'Design and Project Management of Skonkenville Clinic         ',
        total:'R 4.5 mil        ',
        client:'Department of Health         ',
        contact:'Mr. Aaron Baartman         ',
        contactNum:'082-849-5423/ (051) 408-1415        '

    },
    {
        id:41,
        desc:'Construction of Pelonomi Hospital Matsompa and Ithusenf MDR Clinics         ',
        total:'R 290.0 mil        ',
        client:'Department of Health         ',
        contact:'Mr. Luvuyo Penze        ',
        contactNum:'078-926-3527/(051) 408-1415        '

    },
    {
        id:42,
        desc:'Design and Construction of Roads in Wedela        ',
        total:'R 6.0 mil        ',
        client:'Merafong Local Municipality         ',
        contact:'Mr. Thulani Bonkolo        ',
        contactNum:'072-867-2970/ (018) 788-9028        '

    },
    {
        id:43,
        desc:'Technical Advisory services to the Justice Portfolio        ',
        total:'R 2. mil        ',
        client:'National Department of Public Works         ',
        contact:'Mr. Chuma Mbande         ',
        contactNum:'083-328-1133/ (031)584-1760        '

    },
    {
        id:44,
        desc:'Design and Project Management of Roads and Stormwater         ',
        total:'As and when        ',
        client:'Ekurhuleni Metropolitan Municipality        ',
        contact:'Mr. Katlego Ditshego         ',
        contactNum:'079-079-4805/(031)584-1760        '

    },
    {
        id:45,
        desc:'Turnkey Project Implementation for Township Revitalization Programme at Wilhelmina.        ',
        total:'R 13 500.00        ',
        client:'Department of Cooperative Governance & Traditional Affairs         ',
        contact:'Piet Viljoen         ',
        contactNum:'076-682-1435/ (051) 407-6701        '

    },
    {
        id:46,
        desc:'Appointment of Civil and Structural Engineer: Stages 5 and 6: New Police Station Limpopo Muyexe        ',
        total:'R 22 mil        ',
        client:'South African Police Services         ',
        contact:'Colonel B Charl        ',
        contactNum:'082-332-4798 (012) 845-8992        '

    },
    {
        id:47,
        desc:'Appointment of Civil and Structural Engineer: Stages 5 and 6: New Police Station North West Mareetsane.        ',
        total:'R 32 mil        ',
        client:'South African Police Services         ',
        contact:'Colonel B Charl        ',
        contactNum:'082-332-4798 (012) 845-8993        '

    },
    {
        id:48,
        desc:'Upgrading of the Lewisham Tennis Courts         ',
        total:'R 3 mill        ',
        client:'Mogale City Local Municipality         ',
        contact:'Shirley Mathebula        ',
        contactNum:'(011) 951-2233        '

    },
    {
        id:49,
        desc:'JCPZ Environmental Studies and Rehabilitation for COJ        ',
        total:'R 2.5 mill        ',
        client:'Johannesburg City Parks         ',
        contact:'Vusani Tshabalala        ',
        contactNum:'072-646-2000 / (011) 227-9045        '

    },
    {
        id:50,
        desc:'Draught Intervention Project in KZN (Drilling,Pumping and Testing Services)        ',
        total:'R 21 mil        ',
        client:'Department of Water and Sanitation        ',
        contact:'Yasmin Rajkumar        ',
        contactNum:'083-407-3742        '

    },
    {
        id:51,
        desc:'Pre-feasibility Studies for Public Transport Facilities at Katlehing Ward 63.        ',
        total:'R 800 000.00 (FEES)        ',
        client:'Ekurhuleni Metropolitan Municipality        ',
        contact:'Benda Phakane        ',
        contactNum:'072-327-0373        '

    },
    {
        id:52,
        desc:'Appointmenr of Civil Engineering Consultant for the cobstruction of Roads at Etwatwa (Sebata and Ekuthuleni Street).        ',
        total:'R 6.6 mil        ',
        client:'Ekurhuleni Metropolitan Municipality',
        contact:'Nape Seopela         ',
        contactNum:'083-499-3199/ (011) 999-6812        '

    },
    {
        id:53,
        desc:'Appointmenr of Civil Engineering Consultant for the cobstruction of Roads at Etwatwa (Sebata and Ekuthuleni Street).',
        total:'R 17.1 mil        ',
        client:'Ekurhuleni Metropolitan Municipality',
        contact:'Nape Seopela',
        contactNum:'083-499-3199/ (011) 999-6812'

    },
    {
        id:54,
        desc:'Appointmenr of Civil Engineering Consultant for the cobstruction of Roads at Etwatwa (Sebata and Ekuthuleni Street).',
        total:'R 3 mil        ',
        client:'Ekurhuleni Metropolitan Municipality',
        contact:'Nape Seopela',
        contactNum:'083-499-3199/ (011) 999-6812'

    },
    {
        id:55,
        desc:'Appointmenr of Civil Engineering Consultant for the cobstruction of Roads at Etwatwa (Sebata and Ekuthuleni Street).',
        total:'R 17.2 mil        ',
        client:'Ekurhuleni Metropolitan Municipality',
        contact:'Nape Seopela',
        contactNum:'083-499-3199/ (011) 999-6812'

    },
    {
        id:56,
        desc:'Rehabilitation of Jukskei River Park        ',
        total:'R 15.0 mil        ',
        client:'Johannesburg City Parks         ',
        contact:'Vusani Tshabalala        ',
        contactNum:'072-646-200        '

    },

    

    

]